@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  background: rgb(160, 160, 160);
}

.container.dark {
  color: rgb(255, 255, 255);
  background: rgb(50, 50, 50);
  transition: all 0.3s;
}

.divider {
  height: 2px;
  margin: 1rem;
  margin-bottom: 2rem;
  background-color: rgb(160, 160, 160);
}

.container {
  max-width: 500px;
  background: #fff;
  margin: 30px auto;
  overflow: auto;
  min-height: 300px;
  border: 3px solid rgb(50,50,50);
  padding: 30px;
  border-radius: 5px;
  transition: all 0.3s;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.btn {
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}


.mode-btn {
  display: inline-block;
  background: rgb(50,50,50);
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.mode-btn.dark {
  background: #fff;
  color: rgb(50,50,50);
}

.mode-btn:focus {
  outline: none;
}

.mode-btn:active {
  transform: scale(0.98);
}

.mode-btn-block {
  display: block;
  width: 100%;
}

.h4_date {
  font-size: .8rem;
  padding-top: .5rem
}

.category_tag {
  color: black;
  padding-top: .2rem;
  font-size: .9rem;
  padding: 5px;
  background: #ADD8E6;
  width: fit-content;
  border-radius: 5px;
}

.task {
  background: #f4f4f4;
  margin: 5px;
  padding: 10px 20px 0px;
  cursor: pointer;
  font-size: 1.2rem;
  border-radius: 10px;
  border: 1px solid rgb(50,50,50);
  box-shadow: 20px 20px 50px grey;
}

.task:hover {
  transform: scale(1.01);
}

.task.reminder {
  border-left: 5px solid green;
}

.task.completed {
  text-decoration: line-through;
  background-color: rgb(160, 160, 160);
  opacity: 0.5;
}

.task.dark {
  color: rgb(255, 255, 255);
  background: rgb(105, 105, 105);
  margin: 5px;
  padding: 10px 20px 0px;
  box-shadow: none;
}

.task.dark:hover {
  transform: scale(1.03);
}

.complete {
  cursor: pointer;
  color: green;
}

.complete.dark {
  color: rgb(88, 233, 88);
}

.delete {
  cursor: pointer;
  color: red;
}

.delete.dark {
  color: rgb(255, 58, 58)
}

.task h3 {
  align-items: center;
  justify-content: space-between;
  max-height: 100px
}

.add-form {
  margin-bottom: 40px;
}

.form-control {
  margin: 20px 0;
}

.form-control label {
  display: block;
}

.form-control input {
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
}

.form-control-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-control-check label {
  flex: 1;
}

.form-control-check input {
  flex: 2;
  height: 20px;
}

footer {
  margin-top: 30px;
  text-align: center;
}